<template>
  <div class="select-box py-3">
<!--    <div class="drop-down">-->
      <v-select
        :items="items"
        v-model="currentValue"
        :error-messages="errorMessages"
        :disabled = disabled
        hide-details="auto"
      >
        <template v-slot:label>
          {{ label }} <span v-if="necessarily" class="red-dot">*</span>
        </template>
      </v-select>
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'web-drop-down-box',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    necessarily: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentValue: null
  }),
  watch: {
    value () {
      this.currentValue = this.value
    },
    currentValue () {
      if (this.currentValue !== this.value) {
        this.$emit('change', this.currentValue)
      }
    }
  },
  mounted () {
    this.currentValue = this.value
  },
  methods: {
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #FF5050
//.select-box
//  margin-top: 24px
//  margin-left: 3px
//  width: 98%
//  height: 56px
//  border-radius: 8px
//  position: relative
//
//.drop-down
//  width: 100%
//  font-size: 20px
//  position: absolute
//  top: 0
//
//.select-box .v-input__slot .v-select__slot .v-select__selections input
//  float: left
//
//.select-box .v-input__slot .v-select__slot .v-select__selections
//  display: flex
//  justify-content: right
//  flex-direction: row-reverse
//
//.select-box .v-select__slot
//  width: fit-content
//
//.select-box .v-input__slot .v-select__slot .v-select__selections input
//  max-width: 15px
//  text-align: right
//
//.select-box .v-select__slot
//  color: #666870
//  font-weight: 300
//  font-size: 13px
//
//.select-box .v-input__prepend-inner
//  color: #666870
//  font-weight: 300
//  font-size: 13px

</style>
