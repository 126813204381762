<template>
  <div class="py-3">
    <v-text-field
      v-if="assist.tool.isEmpty(finalMask) || (assist.tool.isNotEmpty(currentValue) && currentValue.indexOf('*') !== -1)"
      :readonly="disabled"
      hide-details="auto"
      v-model="currentValue"
      :error-messages="errorMessages"
      :icon-value="iconValue"
      :type="type"
      v-on="$listeners"
      :class="{ 'p-text-disabled': disabled }"
    >
      <template v-slot:label>
        {{ label }} <span v-if="necessarily" class="red-dot">*</span>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot:prepend-inner>
        <slot name="prepend-inner">
          <template v-if="format === 'currency'">$</template>
        </slot>
      </template>
    </v-text-field>
    <v-text-field
      v-else-if="finalMask"
      v-mask="finalMask"
      :readonly="disabled"
      hide-details="auto"
      v-model="currentValue"
      :error-messages="errorMessages"
      :type="type"
      v-on="$listeners"
      :class="{ 'p-text-disabled': disabled }"
    >
      <template v-slot:label>
        {{ label }} <span v-if="necessarily" class="red-dot">*</span>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot:prepend-inner>
        <slot name="prepend-inner">
          <template v-if="format === 'currency'">$</template>
        </slot>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'web-text-field-box',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    fieldKey: {
      type: String,
      default: ''
    },
    iconValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    necessarily: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    }
  },
  data: () => ({
    assist: {
      tool: tool
    },
    currentValue: null,
    finalMask: ''
  }),
  watch: {
    value () {
      this.currentValue = this.value
      this.$nextTick(() => {
        this.finalMask = this.mask
        this.$forceUpdate()
      })
    },
    currentValue () {
      if (this.currentValue !== this.value) {
        this.$emit('change', this.currentValue)
      }
    }
  },
  mounted () {
    this.currentValue = this.value
    if (tool.isNotEmpty(this.value)) {
      this.finalMask = this.mask
    }
  },
  methods: {
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #FF5050
//.p-text-field .v-input .v-input__control .v-input__slot .v-text-field__slot input
//  text-align: right
//
////.p-text-field .v-input .v-input__control .v-input__slot .v-text-field__slot input[type="password"]
////  font-family: 'Verdana'
////  font-size: 20px
//
////.p-text-field .v-input .v-input__control .v-input__slot
////  color: #666870
////  font-weight: 300
////  font-size: 13px
//
////.p-text-field
////  margin-top: 24px
////  margin-left: 3px
////  width: 98%
////  height: 56px
////  border-radius: 8px
////  position: relative
//
//.p-text-field .p-text-disabled.theme--light.v-input input
//  color: rgba(0,0,0,.38)!important
//
//.p-text-field .p-text-disabled.v-text-field.v-text-field--solo .v-input__prepend-inner
//  color: rgba(0,0,0,.38)!important

</style>
