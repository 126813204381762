<template>
  <div class="date-select-box py-3">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      max-width="290px"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          v-bind="attrs"
          v-on="on"
          readonly
          :error-messages="errorMessages"
          hide-details="auto"
        >
          <template v-slot:label>
            {{ label }} <span v-if="necessarily" class="red-dot">*</span>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-if="menu && !disabled"
        v-model="datePickerValue"
        color="secondary"
        no-title scrollable
        :allowed-dates="getAllowedDate"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
import moment from 'moment'

export default {
  name: 'web-date-select-box',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    necessarily: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowed: {
      type: String,
      default: ''
    },
    lessDate: {
      type: [Number, String, Date],
      default: null
    },
    availableDate: {
      type: Array,
      default: () => null
    },
    moreDate: {
      type: [Number, String],
      default: null
    },
    numberDate: {
      type: [Boolean, String],
      default: false
    },
    holiday: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    }
  },
  data: () => ({
    assist: {
      tool: new Tool()
    },
    date: null,
    // 年月日
    datePickerValue: null,
    menu: false,
    focusOn: false,
    pattern: null
  }),
  watch: {
    value () {
      this.date = this.value
      this.datePickerValue = this.assist.tool.formatDateWithPattern(this.value, 'yyyy-mm-dd')
    },
    datePickerValue () {
      if (this.assist.tool.isNotEmpty(this.pattern) && this.value !== this.assist.tool.formatDateWithPattern(this.datePickerValue, this.pattern)) {
        this.$emit('change', this.assist.tool.formatDateWithPattern(this.datePickerValue, this.pattern))
      }
    },
    'store.getters.getDateTimePatterns': {
      handler () {
        this.pattern = this.$store.getters.getDateTimePatterns.date
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.pattern = this.$store.getters.getDateTimePatterns.date
    if (this.assist.tool.isNotEmpty(this.value)) {
      this.date = this.value
      this.datePickerValue = this.assist.tool.formatDateWithPattern(this.value, 'yyyy-mm-dd')
    }
  },
  methods: {
    getAllowedDate (val) {
      let result = true

      if (this.allowed) {
        if (this.allowed.indexOf('start-now') !== -1) {
          result = result && (val >= moment().format('YYYY-MM-DD'))
        }

        if (this.allowed.indexOf('start-tomorrow') !== -1) {
          result = result && (val > moment().format('YYYY-MM-DD'))
        }

        if (this.allowed.indexOf('end-now') !== -1) {
          result = result && (val <= moment().format('YYYY-MM-DD'))
        }
      }
      if (this.allowed.indexOf('no-weekend') !== -1) {
        result = result && !this.assist.tool.isWeekend(val)
      }

      if (this.allowed.indexOf('no-holiday') !== -1) {
        result = result && !this.assist.tool.isHoliday(val)
      }

      if (this.lessDate) {
        result = result && (val <= moment(this.lessDate).format('YYYY-MM-DD'))
      }

      if (this.moreDate) {
        result = result && (val >= moment(this.moreDate).format('YYYY-MM-DD'))
      }
      if (this.availableDate) {
        const availableDate = this.availableDate
        const pattern = this.store.getters.getDateTimePatterns.date.toUpperCase()
        const value = moment(val).format(pattern)
        result = result && (availableDate.includes(value))
      }
      return result
    }
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #FF5050
//.date-select-box
//  width: 98%
//  height: 56px
//  border-radius: 8px
//  position: relative
//  margin-top: 24px
//  padding-left: 10px
//
//.date-select-box .v-input .v-input__control
//  position: absolute
//  left: 3px
//  top: 0
//
//.date-select-box .v-input__prepend-inner
//  color: #666870
//  font-weight: 300
//  font-size: 13px
//
//.date-select-box .v-text-field__slot input
//  text-align: right
//  color: #666870
//  font-weight: 300
//  font-size: 13px

</style>
