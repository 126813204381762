<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ validate, reset }">
      <v-row>
        <template v-for="(item, index) in layoutSetting">
          <v-col
            :cols="row ? 12 : item.cols === undefined ? 12 : (item.cols)"
            :key="`v-flex-${index}`"
            :class="(item.class === undefined ? '' : item.class)"
            class="py-0"
          >
            <ValidationProvider
              :name="item.label"
              :rules="item.rules"
              v-slot="{ errors }"
            >
              <p v-if="item.controlType === 'title'">{{ item.label }} : {{ assist.currency.format(current[item.fieldKey]) }}</p>
              <web-date-select-box
                v-else-if="item.controlType === 'date'"
                v-model="current[item.fieldKey]"
                :necessarily="isNecessarily(item)"
                :label="item.label"
                :lessDate="item.minDate"
                :disabled="item.disabled"
                :error-messages="errors[0]"
                :allowed="item.allowed"
                :class="className"
                @change="onChange(item)"
              ></web-date-select-box>
              <web-drop-down-box
                v-else-if="item.controlType === 'select'"
                v-model="current[item.fieldKey]"
                :necessarily="isNecessarily(item)"
                :items="item.items"
                :disabled="item.disabled"
                :label="item.label"
                :error-messages="errors[0]"
                :class="className"
              >
              </web-drop-down-box>
              <v-divider
                v-else-if="item.controlType === 'divider'"
                :inset = "item.inset"
                class="divider"
              >
              </v-divider>
              <div
                v-else-if="item.controlType === 'blank-divider'"
                :class="className"
              >
              </div>
              <web-text-field-box
                v-else
                v-model="current[item.fieldKey]"
                :fieldKey="item.fieldKey"
                :necessarily="isNecessarily(item)"
                :disabled="item.disabled"
                :label="item.label"
                :mask="item.mask"
                :format="item.format"
                :error-messages="errors[0]"
                :class="className"
                @blur="onBlurAction(item.fieldKey)"
              ></web-text-field-box>
            </ValidationProvider>
          </v-col>
          <v-col v-if="item.force" :key="`v-flex-empty-${index}`" cols="6"></v-col>
        </template>
      </v-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Tool, Currency } from '@/assets/js/util'
import WebTextFieldBox from '@/components/base/WebTextFieldBox'
import WebDropDownBox from '@/components/base/WebDropDownBox'
import WebDateSelectBox from '@/components/base/WebDateSelectBox'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'web-form-box',
  components: {
    WebDateSelectBox,
    WebDropDownBox,
    WebTextFieldBox,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    layoutSetting: {
      type: Array,
      default: () => []
    },
    row: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (val) {
      this.current = val
    }
  },
  data () {
    return {
      current: this.value,
      assist: {
        tool: tool,
        currency: currency
      }
    }
  },
  methods: {
    onBlurAction (key) {
      this.$emit('blur', key)
    },
    isNecessarily (item) {
      const str = item.rules
      if (tool.isNotEmpty(str)) {
        return str.indexOf('required') !== -1
      }
      return false
    },
    onChange (item) {
      if (item.fieldKey === 'effectiveDate') {
        this.$emit('changeEffectiveDate', item.fieldKey)
      }
    }
  }
}
</script>
<style lang="sass">
.divider
  margin: 24px 4px 12px 4px
.blank-lg12
  flex-basis: 100%
  flex-grow: 0
  max-width: 100%
  height: 30px
</style>
