<template>
  <div class="d-flex justify-center align-center height-full">
    <v-card flat class="pa-6 width-30p">
      <v-card-title class="f-s-20 px-0">Create Loan</v-card-title>
      <v-card-text class="pa-0">
        <web-form ref="createCustomer"
                  v-model="layouts.data.input"
                  :layout-setting="layouts.settings.form">
        </web-form>
      </v-card-text>
      <v-card-actions class="my-8">
        <web-btn
          theme="primary"
          :height="50"
          width="100%"
          @click="createNewLoan">Go</web-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import WebForm from '@/components/base/WebFormBox'
import CREATE_CUSTOMER from '@/settings/layout-setting/system/create-customer'
import { OnlineApi } from '@/api'
import { Tool, Popup } from '@/assets/js/util'
const tool = new Tool()
const popup = new Popup()
export default {
  name: 'Simulator',
  components: { WebForm, WebBtn },
  data: () => ({
    layouts: {
      settings: {
        form: CREATE_CUSTOMER.FORM
      },
      data: {
        showBtn: false,
        input: {
          email: '',
          firstName: '',
          lastName: '',
          employerName: '',
          streetAddress: '',
          optionalAddress: '',
          accountNo: '738896430',
          routingNo: '211274450',
          portfolioId: null,
          demoFlag: true
        }
      }
    }
  }),
  watch: {
    '$store.getters.getPortfolioJson.portfolioId': {
      handler (val) {
        this.layouts.data.input.portfolioId = val
      },
      deep: true
    }
  },
  mounted () {
    this.layouts.data.input.portfolioId = this.$store.getters.getPortfolioJson.portfolioId
  },
  methods: {
    createNewLoan () {
      this.$refs.createCustomer.$refs.observer.validate().then(result => {
        if (result) {
          OnlineApi.simulateData(this.layouts.data.input, (res) => {
            if (tool.isNotEmpty(res)) {
              const url = res.invitationUrl.split('#')
              this.$router.push(url[1])
            }
          }, (err) => {
            popup.popupForError(err.message)
          })
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
